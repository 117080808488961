import React from 'react'
import { Grid, Typography, Link, Box } from '@material-ui/core'
import { SectionHeaderSmall, TitleMedium, Body2 } from '../../theme/typography'
import CustomButton from '../Button/CustomButton'
import PropTypes from 'proptypes'
import styled from 'styled-components'
import { placeImage } from '../../utils'

const TextContent = styled(Grid)(({ theme, size, ctas }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  minHeight: size === 'large' ? 228 : 100,
  [theme.breakpoints.down('sm')]: {
    textAlign: ctas ? 'center' : 'left',
    minHeight: ctas ? 50 : 100
  }
}))

const IconWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: 120,
  paddingLeft: 12,
  '& img': {
    maxWidth: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'center',
    paddingLeft: 0,
    paddingTop: 16,
    marginBottom: -10,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}))

const ImageWrap = styled.div(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.colors.white,
  maxWidth: 280,
  minHeight: 150,
  width: '80%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: theme.shadows[1],
  borderRadius: 4,
  padding: theme.spacing(2),
  marginTop: 'auto',
  marginBottom: 'auto',
  marginLeft: 'auto',
  [theme.breakpoints.down('sm')]: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  '& img': {
    maxWidth: '90%',
    maxHeight: 100
  }
}))

const Content = styled(Grid)(({ theme, size, ctas, flex }) => ({
  position: 'relative',
  minHeight: size === 'large' ? 228 : 100,
  display: ctas || flex ? 'flex' : 'block',
  [theme.breakpoints.down('sm')]: {
    justifyContent: size === 'large' && 'center',
    textAlign: size === 'large' && 'center'
  }
}))

const SubContentText = styled(Body2)(() => ({
  opacity: 1
}))
const Title = styled(TitleMedium)(({ theme, size }) => ({
  marginTop: size === 'large' ? theme.spacing(1) : 0
}))
const ButtonsBox = styled(Box)(({ theme, split, nomarginbottom }) => ({
  padding: split ? theme.spacing(2, 0, 2) : theme.spacing(2, 3, 2),
  margin: split ? theme.spacing(0, -1) : theme.spacing('auto', 0, 'auto', 'auto'),
  marginBottom: nomarginbottom ? 0 : 'auto',
  '& > a, & > span': {
    margin: theme.spacing(1)
  },
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
    marginRight: 'auto',
    width: '100%',
    '& > a': {
      margin: 0,
      width: '100%'
    }
  }
}))

const Wrapper = styled(Box)(({ theme, maxwidth, size, image, mobilemargins, gradient, ctas }) => {
  return {
    position: 'relative',
    display: 'flex',
    backgroundImage: image
      ? `url(${image})`
      : gradient
      ? 'linear-gradient(96deg, rgb(16, 76, 95), rgb(0, 49, 63) 100%)'
      : '',
    padding:
      size === 'large' ? theme.spacing(2) + 'px ' + theme.spacing(9) + 'px' : theme.spacing(2.5),
    color: theme.colors.white,
    backgroundColor: theme.colors.blue5,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: gradient ? 8 : 4,
    overflow: 'hidden',
    maxWidth: maxwidth ? maxwidth : '585px',
    minHeight: size === 'large' ? 228 : 100,
    textAlign: 'left',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      borderRadius: gradient ? 8 : 0,
      margin: mobilemargins ? '0px ' + -theme.spacing(2) + 'px' : '0',
      minHeight: size === 'large' ? 380 : 100,
      padding: size === 'large' ? theme.spacing(2.5) : theme.spacing(2)
    },
    '&:hover': {
      color: theme.colors.white,
      boxShadow: ctas
        ? '2px 2px 4px 0 rgb(13 49 63 / 20%), 1px 1px 4px 2px rgb(13 49 63 / 20%)'
        : 'none'
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      backgroundColor: theme.colors.blue5,
      opacity: 0.4
    }
  }
})

function NewsBanner(props) {
  const banner = (
    <Wrapper
      underline="none"
      size={props.size}
      image={props.image}
      mobilemargins={props.mobileMargins ? 'true' : undefined}
      maxwidth={props.maxWidth}
      gradient={props.gradient}
      ctas={props.ctas}
    >
      <Content container spacing={3} size={props.size} ctas={props.ctas}>
        {props.icon && <IconWrap>{placeImage(props.icon, { svg: true })}</IconWrap>}
        <TextContent
          ctas={props.ctas}
          item
          size={props.size}
          md={props.ctas ? (props.ctas.length > 1 ? 5 : 7) : !props.image2 ? 12 : 8}
          xs={12}
        >
          {props.subTitle && (
            <SectionHeaderSmall color="inherit">{props.subTitle}</SectionHeaderSmall>
          )}
          {props.title && (
            <Title color="inherit" size={props.size} component="h3">
              {props.title}
            </Title>
          )}
          {props.content && <Typography variant="h3">{props.content}</Typography>}
          <SubContentText>{props.subContent}</SubContentText>
        </TextContent>

        {props.ctas && (
          <ButtonsBox nomarginbottom={props.noMarginBottom ? 'true' : undefined}>
            {props.ctas.map((cta, i) => {
              return (
                <CustomButton
                  key={`banner-cta${i}`}
                  href={cta.href}
                  title={cta.title}
                  rel={cta.rel}
                  target={cta.target}
                  variant={i === 0 ? 'contained' : 'outlined'}
                  color={i === 0 ? 'white' : 'whiteOutlined'}
                >
                  {cta.text}
                </CustomButton>
              )
            })}
          </ButtonsBox>
        )}

        {props.image2 && (
          <Grid item md={4} xs={12} style={{ display: 'flex' }}>
            <ImageWrap>
              <img src={props.image2} alt={props.image2Alt} />
            </ImageWrap>
          </Grid>
        )}
      </Content>
    </Wrapper>
  )

  if (props.url || props.ctas.length === 1) {
    return (
      <Link
        underline="none"
        href={props.url ? props.url : props.ctas[0].href}
        target={props.target ? props.target : props.ctas[0].target}
      >
        {banner}
      </Link>
    )
  } else {
    return <>{banner}</>
  }
}

export default NewsBanner

NewsBanner.propTypes = {
  mobilemargins: PropTypes.bool,
  size: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  image: PropTypes.string,
  image2: PropTypes.string,
  topTitle: PropTypes.string,
  content: PropTypes.string,
  align: PropTypes.string,
  url: PropTypes.string
}

NewsBanner.defaultProps = {
  size: '',
  align: null,
  mobilemargins: false
}
