import React from 'react'
import { Container, Typography, Grid, Box } from '@material-ui/core'
import { TitleH2Bold } from '../../theme/typography'
import PropTypes from 'proptypes'
import styled from 'styled-components'
import Svg from 'react-inlinesvg'
import CombindedBlue2 from '../../images/bgimages/combined-blue2.svg'

const IconBox = styled('div')(() => ({
  position: 'absolute',
  width: 100,
  height: 100,
  margin: 'auto',
  top: -55,
  left: '50%',
  transform: 'translate(-50%, 0)',
  display: 'flex',

  '& .blue-bg-image': {
    width: 100,
    height: 100,
    transform: 'rotate(45deg)',
    '&:first-child': {
      transform: 'rotate(75deg)'
    },
    '&:last-child': {
      transform: 'rotate(15deg)'
    }
  },
  '& .icon-image': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}))

const Text = styled(Typography)(() => ({
  '& p:last-child': {
    marginBottom: 0
  }
}))
const BlueBox = styled(Box)(({ theme }) => ({
  color: theme.colors.white,
  borderRadius: 4,
  padding: theme.spacing(4.5, 3, 3),
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  boxShadow: '2px 2px 10px 0 rgb(32 102 127 / 30%), 0 1px 3px 0 rgb(32 102 127 / 30%)'
}))

const GridWrap = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(6) + 'px !important',
  '& div:first-child .blue-box': {
    backgroundImage: 'linear-gradient(110deg, #30819e, #20667f)'
  },
  '& div:last-child .blue-box': {
    backgroundImage: 'linear-gradient(110deg, #104c5f, #00313f)'
  },
  '& div:nth-child(2) .blue-box': {
    backgroundImage: 'linear-gradient(110deg, #20667f, #104c5f)'
  }
}))

function AboutTruphone(props) {
  return (
    <Box pt={15} pb={4}>
      <Container maxWidth="lg" align="center">
        <TitleH2Bold component="h2" gutterBottom>
          {props.title}
        </TitleH2Bold>
        <Typography variant="body1" color="textSecondary">
          {props.subtitle}{' '}
        </Typography>
        <GridWrap container spacing={5}>
          {props.list &&
            props.list.map((item, i) => {
              return (
                <Grid item xs={12} md={4} key={`about-item${i}`}>
                  <BlueBox className="blue-box">
                    <IconBox>
                      <Svg className="blue-bg-image" src={CombindedBlue2} alt="blue-circle" />

                      <img className="icon-image" src={item.icon.url} alt="" />
                    </IconBox>

                    <Text
                      variant="body1"
                      component="div"
                      dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                  </BlueBox>
                </Grid>
              )
            })}
        </GridWrap>
      </Container>
    </Box>
  )
}

export default AboutTruphone

AboutTruphone.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      imageAlt: PropTypes.string,
      content: PropTypes.string
    })
  )
}

AboutTruphone.defaultProps = {
  list: []
}
