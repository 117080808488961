import { Box, Link, makeStyles } from '@material-ui/core'
import React from 'react'
import { SectionHeaderSmall } from '../../theme/typography'
import PropTypes from 'proptypes'
import styled from 'styled-components'
import { placeImage } from '../../utils'
import WhiteCircle from '../../images/bgimages/white-circle.svg'

const useStyles = makeStyles((theme) => ({
  whiteImage: {
    transform: 'rotate(0) scale(1)',
    transition: 'transform .3s ease-out',
    marginTop: -4,
    transformOrigin: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%'
    }
  },
  iconImage: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '30px',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '35px'
    }
  }
}))
const BoxWrap = styled(Box)(({ theme }) => {
  return theme.breakpoints
    ? {
        justifyContent: 'center',
        display: 'flex',
        padding: '1rem 0 3rem',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
          alignItems: 'flex-start',
          padding: '1rem 0',
          flexDirection: 'row'
        }
      }
    : {
        justifyContent: 'center',
        display: 'inline-block',
        padding: '1rem 0 3rem',
        flexDirection: 'column',
        alignItems: 'center'
      }
})
const LinkWrap = styled(Link)(({ theme }) => {
  return theme.breakpoints
    ? {
        textAlign: 'center',
        position: 'relative',
        display: 'inline-block',
        maxWidth: 150,
        margin: theme.spacing(0, 0.5),
        [theme.breakpoints.up('sm')]: {
          margin: theme.spacing(0, 2)
        },
        '&:hover .white-circle': {
          transform: 'rotate(360deg) scale(1.2)'
        }
      }
    : {
        textAlign: 'center',
        position: 'relative',
        display: 'inline-block',
        maxWidth: 150,
        margin: '0px 8px',
        '&:hover .white-circle': {
          transform: 'rotate(360deg) scale(1.2)'
        }
      }
})
const ImageWrap = styled(Box)({
  position: 'relative',
  width: 100,
  height: 100,
  margin: 'auto'
})

const txtStyle = {
  fontSize: '16px',
  fontWeight: 'bold'
}

function AppleDevices(props) {
  const classes = useStyles(props)
  return (
    <BoxWrap>
      {props.devices && props.devices.length > 0 ? (
        props.devices.map((item, i) => {
          return (
            <LinkWrap href={item.link} key={`device${i}`} underline="none">
              <ImageWrap>
                <img
                  className={classes.whiteImage + ' white-circle'}
                  src={WhiteCircle}
                  placeholder="none"
                  alt="white-circle"
                />
                {placeImage(item.icon, { className: classes.iconImage })}
                {/* <Svg className={classes.iconImage} src={item.icon.url} alt={item.iconAlt} /> */}
              </ImageWrap>
              {props.theme ? (
                <SectionHeaderSmall component="h4" gutterBottom>
                  {item.title}
                </SectionHeaderSmall>
              ) : (
                <div style={txtStyle}>{item.title}</div>
              )}
            </LinkWrap>
          )
        })
      ) : (
        <></>
      )}
    </BoxWrap>
  )
}

export default AppleDevices

AppleDevices.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      href: PropTypes.string,
      icon: PropTypes.string,
      iconAlt: PropTypes.string
    })
  )
}
