import React from 'react'
import ConsumerLayout from '../../components/Layouts/ConsumerLayout'
import Hero from '../../components/Banners/Hero'
import AppleDevices from '../../components/Consumer/AppleDevices'
import ItemCarousel from '../../components/Consumer/ItemCarousel'
import DevicesCarousel from '../../components/Consumer/DevicesCarousel'
import AboutTruphone from '../../components/Consumer/AboutTruphone'
import FloatingBlock from '../../components/Consumer/FloatingBlock'
import HeroConsumer from '../../components/Consumer/HeroConsumer'
import ParallaxImages from '../../components/Consumer/ParallaxImages'
import ConsumerTabs from '../../components/Consumer/ConsumerTabs'
import Logos from '../../components/Other/Logos'
import Banner from '../../components/Banners/Banner'
import { Container, Box, makeStyles } from '@material-ui/core'
import { ParallaxProvider } from 'react-scroll-parallax'
import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import FAQList from '../../components/Other/FAQList'
import Svg from 'react-inlinesvg'
import HomeLeft from '../../images/bgimages/home-left.svg'
import HomeRight from '../../images/bgimages/home-right.svg'
import CombinedBlue2a from '../../images/bgimages/combined-blue2a.svg'
import CombinedBlueLarge from '../../images/bgimages/combined-blue2-large.svg'

const useStyles = makeStyles((theme) => ({
  hero: {
    position: 'relative',
    overflow: 'hidden',
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    paddingTop: 40,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(15)
    },
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(8)
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      zIndex: -1,
      backgroundImage: 'linear-gradient(to bottom, #ffffff, #f7f6f6 50%, #f7f6f6)'
    }
  },
  heroLeft: {
    position: 'absolute',
    bottom: 10,
    left: -280,
    [theme.breakpoints.up('md')]: {
      left: -190
    },
    '@media (min-width: 1400px)': {
      left: 0
    }
  },
  heroRight: {
    position: 'absolute',
    bottom: 10,
    right: -353,
    [theme.breakpoints.up('md')]: {
      right: -270
    },
    '@media (min-width: 1400px)': {
      right: 0
    }
  },
  gradient: {
    position: 'relative',
    overflow: 'hidden',
    backgroundImage:
      'linear-gradient(to bottom, #299dc1, rgba(41, 157, 193, 0.8) 50%, rgba(41, 157, 193, 0.6))',
    paddingTop: theme.spacing(8),
    [theme.breakpoints.up('md')]: {
      padding: '330px 0 0'
    }
  },
  devicesCarouselBottom: {
    position: 'relative',
    backgroundColor: '#f7f6f6',
    height: '7rem',
    padding: '3rem 0 0'
  },
  nextSection: {
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      marginTop: -330
    }
  },
  circleRight: {
    position: 'absolute',
    width: 300,
    top: 300,
    right: -150
  },
  circleLeft: {
    position: 'absolute',
    bottom: 600,
    left: -150
  },
  circleLarge: {
    position: 'absolute',
    zIndex: 0,
    bottom: '-40%',
    width: 1370,
    left: '45%',
    transform: 'translateX(-50%)',
    [theme.breakpoints.up('md')]: {
      bottom: '-37%',
      left: '55%'
    }
  },
  bgImagesWrap: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    bottom: 0,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  }
}))

function ConsumerHome({
  data: { datoCmsSite, datoCmsConsumerHomePage },
  pageContext: { menu, footer, cookiePrompt }
}) {
  const classes = useStyles(datoCmsConsumerHomePage)

  return (
    <ConsumerLayout menu={menu} footer={footer} cookiePrompt={cookiePrompt}>
      <HelmetDatoCms
        seo={datoCmsConsumerHomePage.seoMetaTags}
        favicon={datoCmsSite.faviconMetaTags}
      />
      <ParallaxProvider>
        <div className={classes.hero}>
          <Hero
            maxwitdth="995px"
            titleLarge
            heroBgColor="transparent"
            bgColor="transparent"
            ptsmall
            icon={datoCmsConsumerHomePage.icon}
            topText={datoCmsConsumerHomePage.title}
            title={datoCmsConsumerHomePage.subtitle}
            subtitle={datoCmsConsumerHomePage.description}
            banner={<AppleDevices devices={datoCmsConsumerHomePage.productIcons} />}
          />
          <div className={classes.bgImagesWrap}>
            <img src={HomeLeft} className={classes.heroLeft} placeholder="none" alt="" />
            <img src={HomeRight} className={classes.heroRight} placeholder="none" alt="" />
          </div>
        </div>
        <ParallaxImages
          images={[
            datoCmsConsumerHomePage.tabletImage,
            datoCmsConsumerHomePage.watchImage,
            datoCmsConsumerHomePage.phoneImage
          ]}
          mobileImage={datoCmsConsumerHomePage.devicesMobileImage}
        />
        <div id="next-section" className={classes.nextSection}>
          <div className={classes.gradient}>
            <Box mb={6}>
              <ItemCarousel
                bgcolor="dark"
                type="bluebox"
                title={datoCmsConsumerHomePage.cardCarouselTitle}
                content={datoCmsConsumerHomePage.cardCarouselDescription}
                list={datoCmsConsumerHomePage.cardCarouselCards.map((card) => {
                  return {
                    title: card.title,
                    content: card.description,
                    icon: card.icon
                  }
                })}
              />
            </Box>
            <DevicesCarousel
              homepage={true}
              customDots={true}
              autoplay={false}
              list={datoCmsConsumerHomePage.productCarouselSlides.map((item, i) => {
                if (i === 0) {
                  return {
                    topTitle: item.smallTitle,
                    title: item.title,
                    content: item.description,
                    image: item.image,
                    icon: item.bulletImage,
                    ctas: [
                      {
                        appStore: 'Apple',
                        image: item.appStoreImage,
                        href: item.appStoreUrl,
                        openNewTab: item.primaryCtaOpenTab
                      },
                      {
                        appStore: 'Google',
                        image: item.playStoreImage,
                        href: item.playStoreUrl,
                        openNewTab: item.secondaryCtaOpenTab
                      }
                    ]
                  }
                } else {
                  return {
                    topTitle: item.smallTitle,
                    title: item.title,
                    content: item.description,
                    image: item.image,
                    icon: item.bulletImage,
                    ctas: [
                      {
                        text: item.primaryCtaText,
                        href: item.primaryCtaUrl,
                        target: item.primaryCtaOpenTab
                      },
                      {
                        text: item.secondaryCtaText,
                        href: item.secondaryCtaUrl,
                        target: item.secondaryCtaOpenTab
                      }
                    ]
                  }
                }
              })}
            />
            <Svg src={CombinedBlue2a} alt="" className={classes.circleRight} />
            <Svg src={CombinedBlue2a} alt="" className={classes.circleLeft} />
            <Svg src={CombinedBlueLarge} alt="" className={classes.circleLarge} />
            <div className={classes.devicesCarouselBottom}></div>
          </div>
          <div
            style={{
              backgroundColor: '#f7f6f6',
              paddingBottom: '2rem'
            }}
          >
            <ItemCarousel
              type="item"
              title={datoCmsConsumerHomePage.reviewsTitle}
              content={datoCmsConsumerHomePage.reviewsDescription}
              list={datoCmsConsumerHomePage.reviewsReviews}
            />

            <Logos noBorders={true} hover={true} logos={datoCmsConsumerHomePage.companyLogos} />
            <HeroConsumer
              noMarginBottom={true}
              banner={true}
              bgcolor="dark"
              color="#ffffff"
              topText={datoCmsConsumerHomePage.managementTitle}
              title={datoCmsConsumerHomePage.managementSubtitle}
              content={datoCmsConsumerHomePage.managementContent}
              image={datoCmsConsumerHomePage.managementImage}
              ctas={[
                {
                  href: datoCmsConsumerHomePage.appStoreUrl,
                  image: datoCmsConsumerHomePage.appStoreImage,
                  appStore: 'Apple'
                },
                {
                  href: datoCmsConsumerHomePage.playStoreUrl,
                  image: datoCmsConsumerHomePage.playStoreImage,
                  appStore: 'Google'
                },
                {
                  text: datoCmsConsumerHomePage.managementCtaText,
                  href: datoCmsConsumerHomePage.managementCtaLink
                }
              ]}
            />
            <AboutTruphone
              title={datoCmsConsumerHomePage.aboutTruphoneTitle}
              subtitle={datoCmsConsumerHomePage.aboutTruphoneDescription}
              list={datoCmsConsumerHomePage.aboutTruphoneItems}
            />
            <Container maxWidth="lg">
              <Banner
                maxWidth="1000px"
                gradient={true}
                noMarginBottom={true}
                icon={datoCmsConsumerHomePage.esimFloatingBlockIcon}
                content={datoCmsConsumerHomePage.esimFloatingBlockTitle}
                subContent={datoCmsConsumerHomePage.esimFloatingBlockDescription}
                ctas={[
                  {
                    text: datoCmsConsumerHomePage.esimFloatingBlockCtaText,
                    href: datoCmsConsumerHomePage.esimFloatingBlockCtaLink
                  }
                ]}
              />
            </Container>
          </div>
          <FloatingBlock
            maxWidth="68%"
            containerWidth={1048}
            colortop="#f7f6f6"
            colorbottom="#023240"
            title={datoCmsConsumerHomePage.footerTitle}
            footerImage={datoCmsConsumerHomePage.footerImage}
            subtitle={datoCmsConsumerHomePage.footerDescription}
            ctas={[
              {
                href: datoCmsConsumerHomePage.footerCtaLink,
                text: datoCmsConsumerHomePage.footerCtaText
              }
            ]}
          />

          <Hero
            bgColor="#01313f"
            color="#ffffff"
            titleSmall={true}
            pbsmall={true}
            ptsmall={true}
            title={datoCmsConsumerHomePage.contactSupportBannerTitle}
            content={datoCmsConsumerHomePage.contactSupportBannerDescription}
            ctas={[
              {
                text: datoCmsConsumerHomePage.contactSupportBannerCtaText,
                href: datoCmsConsumerHomePage.contactSupportBannerCtaLink,
                target: datoCmsConsumerHomePage.contactSupportBannerCtaOpenNewTab ? '_blank' : ''
              }
            ]}
          />
          {datoCmsConsumerHomePage.faqGroups.length > 0 && (
            <ConsumerTabs
              title={datoCmsConsumerHomePage.faqTitle}
              disableHash={true}
              list={datoCmsConsumerHomePage.faqGroups.map((faqs) => {
                const list = <FAQList feedbackEnabled={false} large={true} list={faqs.faqs} />
                return {
                  title: faqs.title,
                  value: faqs.title,
                  label: faqs.title,
                  content: list
                }
              })}
            />
          )}
        </div>
      </ParallaxProvider>
    </ConsumerLayout>
  )
}

export default ConsumerHome

export const query = graphql`
  query ConsumerHomePage($locale: String!) {
    datoCmsSite {
      faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    datoCmsConsumerHomePage(locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      icon {
        url
        gatsbyImageData
      }
      title
      subtitle
      description
      tabletImage {
        url
        gatsbyImageData(width: 582)
      }
      watchImage {
        url
        gatsbyImageData(width: 200)
      }
      phoneImage {
        url
        gatsbyImageData(width: 420)
      }
      devicesMobileImage {
        url
        gatsbyImageData(width: 960)
      }
      productIcons {
        title
        icon {
          url
          gatsbyImageData
        }
        link
      }
      cardCarouselTitle
      cardCarouselDescription
      cardCarouselCards {
        title
        description
        icon {
          url
          gatsbyImageData(height: 150)
        }
      }
      productCarouselSlides {
        smallTitle
        title
        description
        image {
          url
          gatsbyImageData(height: 518)
        }
        bulletImage {
          url
          gatsbyImageData
        }
        appStoreImage {
          url
          gatsbyImageData(height: 45, width: 135)
        }
        appStoreUrl
        playStoreImage {
          url
          gatsbyImageData(height: 45, width: 152)
        }
        playStoreUrl
        primaryCtaText
        primaryCtaUrl
        primaryCtaOpenTab
        secondaryCtaText
        secondaryCtaUrl
        secondaryCtaOpenTab
      }
      reviewsTitle
      reviewsDescription
      reviewsReviews {
        title
        content
        date
      }
      companyLogos {
        image {
          url
          gatsbyImageData(height: 60)
        }
        link
        linkText
        openNewTab
      }
      managementTitle
      managementSubtitle
      managementContent
      managementCtaText
      managementCtaLink
      managementCtaOpenNewTab
      appStoreImage {
        url
        gatsbyImageData(height: 45, width: 135)
      }
      appStoreUrl
      playStoreImage {
        url
        gatsbyImageData(height: 45, width: 152)
      }
      playStoreUrl
      managementImage {
        url
        gatsbyImageData(width: 524, height: 554)
      }
      aboutTruphoneTitle
      aboutTruphoneDescription
      aboutTruphoneItems {
        icon {
          url
          gatsbyImageData(width: 32, height: 32)
        }
        text
      }
      esimFloatingBlockIcon {
        url
        gatsbyImageData(width: 95, height: 70)
      }
      esimFloatingBlockTitle
      esimFloatingBlockDescription
      esimFloatingBlockCtaText
      esimFloatingBlockCtaLink
      esimFloatingBlockCtaOpenNewTab
      footerImage {
        url
        gatsbyImageData(width: 680, height: 306)
      }
      footerTitle
      footerDescription
      footerCtaText
      footerCtaLink
      footerCtaOpenNewTab
      contactSupportBannerTitle
      contactSupportBannerDescription
      contactSupportBannerCtaText
      contactSupportBannerCtaLink
      contactSupportBannerCtaOpenNewTab
      faqTitle
      faqGroups {
        title
        faqs {
          faqId
          title
          content
        }
      }
    }
  }
`
