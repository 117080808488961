import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container, AppBar, Tabs, Tab, Box, makeStyles } from '@material-ui/core'
import { TitleH2Bold } from '../../theme/typography'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(3)
  },
  container: {
    flexGrow: 1
  },
  appbar: {
    maxWidth: 830,
    margin: 'auto',
    marginTop: theme.spacing(5)
  },
  tabs: {
    borderBottom: 'none',
    position: 'relative',

    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'flex-start'
      }
    }
  },
  tab: {
    width: (props) => `calc(100%/${props.list.length})`,
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    backgroundColor: theme.colors.grey3,
    margin: '4px 0',
    overflow: 'visible',
    '& span': {
      position: 'relative',
      zIndex: 1
    },
    '&:first-child': {
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '25%',
        right: '0',
        height: '50%',
        width: 1,
        backgroundColor: 'rgba(13, 49, 63, 0.2)'
      }
    },
    '&:last-child': {
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '25%',
        left: '0',
        height: '50%',
        width: 1,
        backgroundColor: 'rgba(13, 49, 63, 0.2)'
      }
    },
    '&.Mui-selected': {
      color: theme.colors.white,
      position: 'relative',
      '&::after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        backgroundColor: theme.colors.blue1,
        borderRadius: 4,
        color: theme.colors.white,
        top: -3,
        left: 0,
        width: '100%',
        height: '110%',
        zIndex: 0
      }
    }
  }
}))

export default function ConsumerTabs(props) {
  const classes = useStyles(props)
  const [value, setValue] = useState(props.list[0].value)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  useEffect(() => {
    if (window.location.hash && props.list && !props.disableHash) {
      const tabTitle = decodeURIComponent(window.location.hash.substring(1)).replace(/-/, ' ')
      const selectedTab = props.list.indexOf(
        props.list.find((tab) => tab.label.toLowerCase() === tabTitle)
      )
      if (selectedTab !== -1) {
        setValue(selectedTab + 1)
      }
    }
  }, [])

  const updateUrlFragment = (tabTitle) => {
    if (!tabTitle) return
    const newHash = encodeURIComponent(tabTitle.replace(/ /, '-').toLowerCase())
    if (!props.disableHash) {
      window.location.hash = newHash
    }
  }

  return (
    <>
      {props.list.length > 0 && (
        <Box className={classes.root}>
          <Container maxWidth="lg">
            <TitleH2Bold align="center" gutterBottom>
              {props.title}
            </TitleH2Bold>
            <AppBar className={classes.appbar} position="static" color="default" elevation="0">
              <Tabs
                value={value}
                onChange={handleChange}
                className={classes.tabs}
                centered
                aria-label="tabs component"
                indicatorColor="transparent"
                textColor="secondary"
              >
                {props.list.map((label, i) => {
                  return (
                    <Tab
                      onClick={() => updateUrlFragment(label.label)}
                      className={classes.tab}
                      key={`tab-key-${i}`}
                      value={label.value}
                      label={label.label}
                      {...a11yProps(`${label.value}`)}
                    />
                  )
                })}
              </Tabs>
            </AppBar>
          </Container>
        </Box>
      )}
      <Box className={classes.container}>
        {props.list.map((panel, i) => {
          return (
            <TabPanel
              key={`tabpanel-key-${i}`}
              value={value}
              index={panel.value}
              label={panel.label}
            >
              {panel.content}
            </TabPanel>
          )
        })}
      </Box>
    </>
  )
}

ConsumerTabs.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string,
      content: PropTypes.node
    })
  )
}

ConsumerTabs.defaultProps = {
  list: []
}
