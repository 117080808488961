import { Box, Container, Grid, useMediaQuery, makeStyles } from '@material-ui/core'
import React from 'react'
import PropTypes from 'proptypes'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

const useStyles = makeStyles(() => ({
  mobileDevices: {
    width: '150%',
    left: '-25%',
    '@media (min-width: 650px)': {
      width: '120%',
      left: '-10%'
    },
    '@media (min-width: 800px)': {
      width: '100%',
      left: 0
    }
  }
}))
const GridWrap = styled(Grid)(() => ({
  flexWrap: 'nowrap'
}))
const ImageWrap = styled(Grid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media (min-width: 800px)': {
    '& .parallax0': {
      marginLeft: -220,
      width: 540
    },
    '& .parallax1': {
      width: 165,
      marginTop: -94,
      marginLeft: 0
    },
    '& .parallax2': {
      width: 420,
      marginRight: -220,
      marginTop: -50
    }
  },
  [theme.breakpoints.up('md')]: {
    '& .parallax0': {
      width: 582,
      marginLeft: -110
    },
    '& .parallax1': {
      width: 180
    },
    '& .parallax2': {
      marginRight: -120
    }
  },
  '@media (min-width: 1100px)': {
    '& .parallax1': {
      marginLeft: -84
    },
    '& .parallax2': {
      marginRight: 0,
      marginLeft: -40
    }
  }
}))
const BoxWrap = styled(Box)(({ theme }) => ({
  marginTop: -theme.spacing(15),
  marginBottom: -theme.spacing(5),
  position: 'relative',
  overflow: 'hidden',
  zIndex: 1,
  background: `linear-gradient(to bottom , transparent 30%, #299dc1 30%, #3ca6c7)`,
  [theme.breakpoints.up('md')]: {
    marginTop: -theme.spacing(25)
  }
}))

function ParallaxImages(props) {
  const mobile = useMediaQuery('(max-width: 959px)')
  const classes = useStyles()

  return !mobile ? (
    <BoxWrap>
      <Container maxWidth="lg">
        <GridWrap container>
          {props.images &&
            props.images.map((image, i) => {
              // let y = i === 0 ? 60 : i === 1 ? 60 : 55
              // let y1 = i === 0 ? 65 : i === 1 ? 70 : 95
              return (
                <ImageWrap item xs={4} md={i === 0 ? 5 : i === 1 ? 3 : 4} key={`parallax-img${i}`}>
                  {/* <Parallax y={[y, -y1]} disabled={mobile}> */}
                  <GatsbyImage
                    className={`parallax${i}`}
                    image={image.gatsbyImageData}
                    alt={image.alt}
                  />
                  {/* </Parallax> */}
                </ImageWrap>
              )
            })}
        </GridWrap>
      </Container>
    </BoxWrap>
  ) : (
    <BoxWrap>
      <GatsbyImage
        className={classes.mobileDevices}
        image={props.mobileImage.gatsbyImageData}
        alt=""
      />
    </BoxWrap>
  )
}

export default ParallaxImages

ParallaxImages.propTypes = {
  topText: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  imageAlt: PropTypes.string,
  content: PropTypes.string,
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
      image: PropTypes.string
    })
  )
}
